
import { defineComponent, ref, onMounted, reactive, toRefs } from 'vue'
import expandIcon from '@/hooks/useExpandIcon'
import { useRoute } from 'vue-router';
import { getentityAll, getReleaseData, findEntityByids, getRegionList, releaseDeleteById, getUserList, geAvailBatchList, releaseAddGroup, releaseAddUser, releaseAddList } from '@/API/approve'
import { OrganizationProxy, GroupQueryProxy, EntityProxy, RegionProxy, RegionItemProxy  } from './types'
import { message } from 'ant-design-vue';
import useTableHeight from "@/hooks/useTableHeight";
import { ExceptionMap } from 'ant-design-vue/lib/result';
const columns = [
    {
        title: "User Group",
        dataIndex: "userGroup",
        key: "userGroup",
    },
    {
        title: "Legal Entity",
        dataIndex: "legalEntity",
        key: "legalEntity",
    },
    {
        title: "Region",
        dataIndex: "region",
        key: "region",
    },
    {
        title: "User ID",
        dataIndex: "userId",
        key: "userId",
        slots: { customRender: "userId" },
    },
    {
        title: "User Name",
        dataIndex: "username",
        key: "username",
    },
    {
        title: "User Email",
        dataIndex: "email",
        key: "email",
        width: 220,
        slots: { customRender: "email" },
    },
    {
        title: "Operation",
        dataIndex: "operation",
        key: "operation",
        slots: { customRender: "operation" },
    },
]
export default defineComponent({
    name: '',
    setup() {
        const { tableHeight } = useTableHeight(false);
        // 添加组的参数
        const groupQuery = reactive<GroupQueryProxy>({
            organizationValue: '',
            entityValue: '',
            regionValue: '',
            batchValue: '',
        })

        // batchName batchId
        const { batch, id } = useRoute().query
        const batchParams = {
            batch,
            batchId: id
        }

        // 添加user的参数
        const userValue = ref<string>()

        // 获取Organization
        const organizationAll = ref<EntityProxy[]>()
        const entityAll = ref<EntityProxy[]>()
        const regionAll = ref()
        const userAll = ref()
        const batchAll = ref()

        // 转换organization数据结构
        const transOrganization = (data: OrganizationProxy[]) => {
            // const arr: string[] = []
            // data.forEach((item, index) => {
            //     if (!arr.includes(item.entityCode)) {
            //         arr.push(item.entityCode)
            //         item.parentId = [...item.parentId] as number[]
            //     } else {
            //         // 代表有重复的code
            //         const initIndex = data.findIndex(e => e.entityCode === item.entityCode)
            //         data[initIndex].parentId = [...data[initIndex].parentId, data[index].parentId] as number[]
            //         arrIndex.push(index)
            //     }
            // })
            const arrIndex: string[] = data.map(item => item.entityCode)
            data = data.filter((item, index) => {
                return arrIndex.indexOf(item.entityCode) === index
            }) 
            return data
        }
        let regionData: RegionItemProxy[]= []
        // 转化region 并去重
        const transRegionData = (data: RegionProxy[]) => {
            let arr: RegionItemProxy[] = []
            const res = data.map(item => {
                return item.regionDto
            })
            regionData = res.flat()
            const idArr = res.flat().map(item => item.regionEn)
            arr = res.flat().filter((item, index) => {
                return idArr.indexOf(item.regionEn) === index
            })
            return arr
        }

        // 初始化获取organization
        const initOrganization = () => {
            getentityAll().then(res => {
                // organizationAll.value = transOrganization(res)
                entityAll.value = res
            })
        }

        // 根据bu获取region list
        const initRegion = () => {
            const { buArr: bu } = useRoute().query
            const params = {
                bu
            }
            getRegionList(params).then(res => {
                regionAll.value = transRegionData(res)
            })
        }

        // 获取用户
        const initUserAll = () => {
            getUserList().then(res => {
                userAll.value = res.content.filter(item => item.state)
            })
        }

        // 获取历史batch
        const pathParams = useRoute().query.type === 'External' ? 'selectAvailableExternalBatch' : 'selectAvailableInternalBatch'
        const initBatchAll = () => {
            const params = { batchId: id }
             geAvailBatchList(pathParams, params).then(res => {
                 batchAll.value = res
             })
        }

        // 改变entity获取orgaization
        const changeEntity = () => {
            groupQuery.organizationValue = ''
            organizationAll.value = []
            groupQuery.regionValue = ''
            if (groupQuery.entityValue) {
                const item = entityAll.value?.find(item => item.entityCode === groupQuery.entityValue) 
                if (item) {
                    findEntityByids(item.id).then(res => {
                      organizationAll.value = res
                  })
                } 
              
            }
        }
      
        // 获取table list getExternalReleaseListByBatch
        const tablePath = useRoute().query.type === 'External' ? 'getExternalReleaseListByBatch' : 'getInternalReleaseListByBatch'
        const dataSource = ref()
        const expandedRowKeys = ref<string[]>([])

        const handleExpand = (expanded: boolean, record: any) => {
          if (expanded) {
            expandedRowKeys.value.push(record.id)
          } else {
            const index = expandedRowKeys.value.indexOf(record.id)
            if (index !== -1) {
              expandedRowKeys.value.splice(index, 1)
            }
          }
        }

        const initTableData = () => {
            const params = { batchId: id }
            getReleaseData(tablePath, params).then(res => {
                dataSource.value = res
            })
        }

        // 重置输入的内容
        const resetGroupData = () => {
            groupQuery.organizationValue = ''
            groupQuery.entityValue = ''
            groupQuery.regionValue = ''
        }

        const regionParams = (regionName: string) => {
            const idArr = regionData.map(item => item.parentId)
            const res = regionData.map(item => {
                if (item.regionEn === regionName) {
                    return {
                        regionId: item.parentId,
                        bu: item.buId,
                        regionName: item.regionEn
                    }
                } 
            }).filter((item, index) => {
                return idArr.indexOf(item?.regionId as string) === index
            })
            return res
        }

        const addPath = useRoute().query.type === 'External' ? 'ex' : 'in'
        // 添加一组
        const handlerAddGroup = () => {
            const { organizationValue: userGroupCode, entityValue: legalEntityCode, regionValue: regionName } = groupQuery
            let regionForm: any[] = []
            if (regionName) {
                // regionId = (regionAll.value.find((item: {regionEn: string}) => item.regionEn === regionName)).parentId
                regionForm = regionParams(regionName)
            }
            const userGroupName = organizationAll.value?.find(item => item.entityCode === userGroupCode)?.entityName
            const params = {
                userGroupCode,
                userGroupName,
                legalEntityCode,
                regionForm,
            }
            if (!userGroupCode || !legalEntityCode) {
                message.error("Please select 'User Group' and 'Entity'.")
                return 
            }
           
            Object.assign(params, batchParams)
            releaseAddGroup(addPath, params).then(() => {
                initTableData()
                resetGroupData()
            })
        }

        // 添加user
        const handlerAddUser = () => {
            if (!userValue.value) {
                message.error("Please select 'Individual User'.")
                return
            }
            const userItem = userAll.value.find((item: {email: string}) => item.email === userValue.value)
            Object.assign(userItem, batchParams)
            releaseAddUser(addPath, userItem).then(() => {
                initTableData()
                userValue.value = ''
            })
        }

        // 添加list
        const handlerAddList = () => {
            if (!groupQuery.batchValue) {
                message.error("Please select the 'Target User From'.")
                return
            }
            const params = {
                hisBatchId: batchAll.value.find((item: {batch: string; batchId: string}) => item.batch === groupQuery.batchValue).batchId
            }
            Object.assign(params, batchParams)
            releaseAddList(addPath, params).then(() => {
                initTableData()
                groupQuery.batchValue = ''
            })
        }

        // 删除
        const deletePath = useRoute().query.type === 'External' ? 'deleteExternalById' : 'deleteInternalById'
        const handlerDelete = (id: string) => {
            const params = { id }
            releaseDeleteById(deletePath, params).then(() => {
                message.success('删除成功')
                initTableData()
            })
        }
        // 初始化数据
        const init = () => {
            initOrganization()
            initRegion()
            initUserAll()
            initTableData()
            initBatchAll()
        }

        onMounted(() => {
            init()
        })
        
        const setRowClassName = (record: any, index: number) => {
            let className = null
            if (!record.userGroup && !record.legalEntity && !record.region) {
                className = 'release-user-backcolor'
            }
            return className
        }

        return {
            tableHeight,
            columns,
            dataSource,
            expandedRowKeys,
            expandIcon,
            setRowClassName,

            ...toRefs(groupQuery),
            userValue,

            organizationAll,
            entityAll,
            userAll,
            regionAll,
            batchAll,

            changeEntity,
            handlerAddGroup,
            handlerAddUser,
            handlerAddList,
            handlerDelete,
            handleExpand
        }

    }
 });
